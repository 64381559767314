export const emailAuth = '/treasury-api/auth/email/';
export const emailAuthCheck = '/treasury-api/auth/email/check/';
export const anamnesisReport = '/treasury-api/diga/anamnesis-report/';
export const anamnesisReportDownload =
  '/treasury-api/diga/anamnesis-report/download/';
export const redeem = '/treasury-api/diga/redeem/';

/**
 * Select the step of the reimbursement flow
 * @param {step} step Select what step you want to navigate to
 */

const DE_REIMBURSMENT_URL = '/de/reimbursement';
const REIMBURSMENT_URL = '/reimbursement';

export const reimbursementPath = (step: number, locale?: string) =>
  locale === 'de'
    ? `${DE_REIMBURSMENT_URL}/step-${step}/`
    : `${REIMBURSMENT_URL}/step-${step}/`;

export const reimbursementSuccess = '/reimbursement/success/';
export const faqLink =
  'https://cara-care.zammad.com/help/de-de/11-cara-care-fur-reizdarm';
export const doctorsLetter =
  'https://res.cloudinary.com/cara-care/image/upload/v1630513779/IBS%20Program/Doctors_letter_Version_01.09.2021_xnj0t8.pdf';
export const infoFlyer =
  'https://res.cloudinary.com/cara-care/image/upload/v1630337861/IBS%20Program/Cara_Care_PDF_patients_1_1_m81hds.pdf';
export const contactUsLink =
  'https://cara-care.zammad.com/help/de-de/11-fur-patient-innen/103-kontakt-bei-fragen';
export const qrCode =
  'https://res.cloudinary.com/cara-care/image/upload/v1630514295/IBS%20Program/onlink_to_6mrfmg_small_1_p0jxu2.png';
export const helpLink =
  'https://cara-care.zammad.com/help/de-de/13-wie-erhalte-ich-zugang-zur-app/115-wie-kann-ich-mir-cara-care-fur-reizdarm-verschreiben-lassen';
export const mailChimpLink = 'http://eepurl.com/hMmB7L';

export enum ApiMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const passwordClinicalTrial = 'dogecoin';

export const eligibleTitle = 'Eignungscheck starten';

export const typeFormStudyLink = 'https://cara.care/de/eignungscheck/study';
export const typeformEligiblityCheckerLink = 'https://survey.cara.care/to/qoxlAtP3#src=xxxxx&clid=xxxxx&hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx';
export const typeformEligiblityCheckerLinkProduct =
  'https://cara.care/de/eignungscheck/appwbst';

export const geoLocationFinderLink = 'https://geolocation-db.com/json/';

export const bookDoctorLink =
  'https://app.cituro.com/booking/5983035?presetService=11ed01150f73ed9b9582cb2d0f527aa9#step=1';
export const startKitLink =
  'https://assets.ctfassets.net/pmt5vquvp72j/1UdAkhJLoz38nlC1aLZ0Pf/58e40d6ff0228ff85efeacc1291d5835/HCP_Starter_Kit-WRM-web.pdf';

export const IBS_PDF_URL =
  'https://eu-prod.cara.care/uploads/Cara_Care_fuer_Reizdarm__Intended_Use___v_03.pdf';
export const IBD_PDF_URL =
  'https://eu-prod.cara.care/uploads/Cara_Care_fuer_CED__Intended_Use___v_01.pdf';
export const HB_PDF_URL =
  'https://eu-prod.cara.care/uploads/Cara_Care-fuer_Sodbrennen__Intended_Use___v_01.pdf';

export const REZEPT_URL =
  'https://survey.cara.care/to/bpuzKchE#src=xxxxx&clid=xxxxx&hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx';
export const REZEPT_URL_MARKETING_LP =
  'https://survey.cara.care/to/my3iomq2#src=xxxxx&clid=xxxxx';

export const REZEPT_URL_INFO =
  'https://survey.cara.care/to/HL97t7V5#src=xxxxx&clid=xxxxx&hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx';

export const REZEPT_URL_INFO_EN =
  'https://survey.cara.care/to/OKJdAPyY#src=xxxxx&clid=xxxxx&hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx';

export const CONTACT_US_LINK =
  'https://cara-care.zammad.com/help/de-de/10-sonstiges/119-an-wen-kannst-du-dich-bei-fragen-wenden';
export const CARRERS_LINK = 'https://apply.workable.com/cara-care/';

export const YOUTUBE_B2C_EMBED_URL =
  'https://www.youtube.com/embed/i9oQTvfrCjE';

export const CTA_OPEN_APP_STORE_LINK =
  'https://cara.app.link/DE-migration-download-cara-care-fuer-reizdarm';

export const REQUEST_MATERIALS_HUBSPOT_FORM_FORM_ID =
  '19da184d-4da3-4dd4-8a0d-a8717a30fb5f';
export const REQUEST_MATERIALS_HUBSPOT_FORM_PORTAL_ID = '26214907';

export const REQUEST_MATERIALS_MARKETING_LP_FORM_FORM_ID =
  '4bbe381b-3bb1-410c-9ede-fb7270b36194';
export const REQUEST_MATERIALS_MARKETING_LP_FORM_PORTAL_ID = '26214907';

export const HUBSPOT_MEETING_IBRAHIM_LINK =
  'https://meetings-eu1.hubspot.com/isabell-adler';

export const HUBSPOT_MEETING_MANU_LINK =
  'https://meetings-eu1.hubspot.com/marcel-nau';

export const HUBSPOT_MEETING_GHAZALEH_LINK =
  'https://meetings-eu1.hubspot.com/ghazaleh';

export const NEW_LANDING_PAGE_ELIBIGLITY_CHECKER_LINK =
  'https://survey.cara.care/to/qoxlAtP3#src=xxxxx&clid=xxxxx&hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx';

export const ENGLISH_STUDY_LINK = 'https://cara.care/en/press/newstudy';
export const GERMAN_STUDY_LINK = 'https://cara.care/de/press/neuestudie';

export const ELIGIBILITY_CHECKER_MARKETING_LP_LINK =
  'https://survey.cara.care/to/MVFo599G';

export const GooglePlayStore =
  'https://play.google.com/store/apps/details?id=com.gohidoc.caraeu';
export const AppleStore =
  'https://apps.apple.com/de/app/cara-care/id1526811241';

export const LIVE_STORM_LINK = 'https://app.livestorm.co/cara-care';
export const WEBINAR_LINK =
  'https://www.youtube.com/playlist?list=PL4P345OrYIJJiecjgK7vAZvwpynWsO4yq';
